<template>
	<div class="LearningRecords">
		<div class="Records_Tit">
			<div class="Records_Tit_top">
				<span>累计学习：</span>
				<span class="classHour">{{PublicJs.ZHformatSeconds(allStudy)}}</span>
				<el-popover
				    placement="bottom"
				    width="240"
				    trigger="hover"
				    content="系统自动记录学习时长，但课件重复学习将不会被记录">
					<p class="isStart" slot="reference">
						<i class="el-icon-info"></i>
						<span>记录规则</span>
					</p>
				  </el-popover>
			</div>
			<div class="Records_Tit_bottom">
				<div class="studyInfo">
					<span>{{todayStudy}}</span>
				</div>
				<div class="studyInfo">
					<span>{{contiStudy}}</span>
				</div>
				<div class="studyInfo">
					<span>{{finishStudy}}</span>
				</div>
				<div>今日学习（分）</div>
				<div>连续学习（天）</div>
				<div>完成课程（节）</div>
			</div>
		</div>
		<div class="recordsList_box">
			<div style="width: 100%;height: 1px;"></div>
			<div v-if="recordsList.length">
				<div class="recordsList_header">
					<p class="serialNumber">序号</p>
					<p class="courseName">课程名称</p>
					<p class="duration">课程时长</p>
					<p class="startTime">开始学习时间</p>
					<p class="endTime">最后学习时间</p>
					<p class="studyLength">学习时长</p>
				</div>
				<div class="recordsList">
					<div class="recordsItem" v-for="(item,index) in recordsList" :key="index">
						<p class="serialNumber">{{index + 1}}</p>
						<p class="courseName" title="item.cou_name">{{item.cou_name}}</p>
						<p class="duration">{{PublicJs.totalSeconds(item.cou_period)}}</p>
						<p class="startTime">{{item.sig_start_study}}</p>
						<p class="endTime">{{item.sig_end_study}}</p>
						<p class="studyLength">
							<span v-if="item.sig_passtext == 'yes'">已完成</span>
							<span v-else>{{PublicJs.totalSeconds(item.sig_alerdy_period)}}</span>
						</p>
					</div>
				</div>
				<div class="recordsPaginat" v-if="total > pageSize">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :total="total"
					  :page-size="pageSize"
					  @current-change="changPage">
					</el-pagination>
				</div>
			</div>
			<div class="noData" v-else>
				<img src="@/views/images/noData.png" />
				<div class="noDataTips">
					<span>暂无数据</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				recordsList:[],//学习记录列表
				total:0,
				pageSize:10,
				allStudy:0,//累计学时
				thisWeekStudy:0,//本周是否开始学习
				todayStudy:0,//今日学习
				contiStudy:0,//连续学习
				finishStudy:0,//完成课程
			}
		},
		methods: {
			//学习记录列表
			getRecordsList(page){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Time/index", {
					token:window.sessionStorage.getItem('token'),
					page:page,
					limit:this.pageSize,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.allStudy = response.data.data.allStudy;//累计学时
						this.thisWeekStudy = response.data.data.thisWeekStudy;//本周是否开始学习
						this.todayStudy = response.data.data.todayStudy;//今日学习
						if(this.todayStudy > 0){
							var todayStudy = this.todayStudy/60;
							if(todayStudy.toString().indexOf(".") > -1){
								this.todayStudy = Number(todayStudy).toFixed(2)
							}else{
								this.todayStudy	= todayStudy
							}
						}
						this.contiStudy = response.data.data.contiStudy;//连续学习
						this.finishStudy = response.data.data.finishStudy;//完成课程
						this.total = response.data.data.list.total;
						this.recordsList = response.data.data.list.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//分页
			changePage(val){
				//学习记录列表
				this.getRecordsList(val)
			},
		},
		created() {
			//学习记录列表
			this.getRecordsList(1)
		}
	}
</script>

<style scoped="scoped">
	.LearningRecords{
		position: relative;
	}
	.Records_Tit{
		width: 100%;
		height: 210px;
		background-color: #FFF;
		box-shadow: 1px 1px 6px 0px rgba(153,153,153,0.18); 
	}
	.recordsList_box{
		width: 100%;
		min-height: 476px;
		margin-top: 20px;
		background-color: #FFF;
		box-shadow: 1px 1px 6px 0px rgba(153,153,153,0.18); 
	}
	.Records_Tit_top{
		width: 100%;
		height: 66px;
		line-height: 66px;
		padding: 0 28px;
		font-size: 18px;
		color: #f8862f;
		border-bottom: 1px solid #EEEEEE;
	}
	.classHour{
		font-size: 24px;
	}
	.isStart{
		float: right;
		font-size: 0;
		line-height: 20px;
		cursor: pointer;
		margin-top: 23px;
	}
	.el-icon-info{
		color: #999;
		font-size: 18px;
		margin-right: 5px;
		vertical-align: middle;
	}
	.isStart span{
		font-size: 14px;
		color: #333333;
		vertical-align: middle;
	}
	.Records_Tit_bottom{
		padding-top: 15px;
		text-align: center;
		font-size: 14px;
		color: #333;
	}
	.Records_Tit_bottom::after{
		content: "";
		display: block;
		clear: both;
	}
	.Records_Tit_bottom div{
		width: 33.33%;
		float: left;
	}
	.Records_Tit_bottom div.studyInfo{
		height: 80px;
		border-right: 1px solid #EEEEEE;
	}
	.Records_Tit_bottom div.studyInfo:last-child{
		border-right: none;
	}
	.Records_Tit_bottom div.studyInfo span{
		font-size: 24px;
		padding-bottom: 16px;
		position: relative;
	}
	.Records_Tit_bottom div.studyInfo span::after{
		content: "";
		display: block;
		width:30px;
		height: 4px;
		background-color: #536BFF;
		border-radius: 2px;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -15px;
	}
	.recordsList_header{
		width: 100%;
		height: 60px;
		line-height: 60px;
		box-shadow: 1px 1px 6px 0px rgba(153,153,153,0.18);
		text-align: center;
		font-size: 16px;
		color: #333;
	}
	.recordsItem{
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 14px;
		color: #333;
	}
	.recordsItem::after
	.recordsList_header::after{
		content: "";
		display: block;
		clear: both;
	}
	.recordsItem p,
	.recordsList_header p{
		float: left;
	}
	.recordsList_header p.serialNumber,
	.recordsItem p.serialNumber{
		width: 80px;
	}
	.recordsList_header p.courseName,
	.recordsItem p.courseName{
		width: 300px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
	}
	.recordsList_header p.duration,
	.recordsItem p.duration{
		width: 120px;
	}
	.recordsList_header p.startTime,
	.recordsItem p.startTime{
		width: 150px;
	}
	.recordsList_header p.endTime,
	.recordsItem p.endTime{
		width: 150px;
	}
	.recordsList_header p.studyLength,
	.recordsItem p.studyLength{
		width: 100px;
	}
	.recordsItem:nth-child(2n){
	  background-color: #E5F4FA;
	}
	.recordsPaginat{
		text-align: center;
		padding: 20px 0;
	}
	.noData{
		text-align: center;
		width: 100%;
		height: 236px;
		margin-top: 153px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
</style>
